<template>
    <div>
        <div class="page-title">订单列表</div>

        <a-table :columns="table.columns"
                 :rowKey="record => record.id || record.key"
                 :data-source="table.data"
                 :pagination="table.pagination"
                 @change="tableChanged"
                 :locale="{emptyText:'暂无订单'}"
                 class="members-table"
                 :loading="table.loading"
        >
            <div slot="no" slot-scope="id,item,idx">
                {{ idx + 1 }}
            </div>


            <div slot="price" slot-scope="val">
                <span v-if=" val > 0 " class="text-danger">￥{{ val }}</span>
                <span v-else> - </span>
            </div>

            <div slot="member" slot-scope="member">
                <router-link :to="`/ast/members/${member.id}`">
                    <div class="member-info">
                        <div class="member-avatar">
                            <img width="50px" :src="member.avatar" alt="">
                        </div>
                        <div>
                            <div class="member-name mb-2">{{ member.mark_name || member.name }}</div>
                            <div class="member-id">ID {{ member.member_id }}</div>
                        </div>
                    </div>
                </router-link>
            </div>

            <div slot="order" slot-scope="val,item">
                <div class="mb-4">{{ item.out_trade_no }}</div>
                <div>{{ item.created_at }}</div>
            </div>
            <div slot="order-title">
                <div class="mb-4">订单号</div>
                <div>时间</div>
            </div>
        </a-table>

    </div>
</template>

<script>

import api from "../../../repo/api";

export default {
    components: {},
    data() {
        return {
            detailId: 0,
            query: {
                id: 0
            },
            course: {},
            table: {
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        width: '110px',
                    },
                    {
                        slots: {title: 'order-title'},
                        dataIndex: 'out_trade_no',
                        scopedSlots: {customRender: 'order'},
                    },
                    {
                        title: '用户',
                        dataIndex: 'member',
                        scopedSlots: {customRender: 'member'},
                    },
                    {
                        title: '支付金额',
                        dataIndex: 'pay_amount',
                        scopedSlots: {customRender: 'price'},
                    },
                    {
                        title: '订购产品',
                        dataIndex: 'subject',
                        scopedSlots: {customRender: 'def'},
                    },

                ],
                loading: true,
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                    showTotal: (total, range) => {
                        return `当前${range[0]}-${range[1]}，共 ${total} 笔订单`;
                    },
                },
                data: []
            },
        }
    },
    mounted() {
        this.getFirstList()
    },
    methods: {
        getFirstList() {
            this.query.id = this.$route.params.id

            this.getList()
        },
        getList() {

            this.table.loading = true

            api.get('/course/orders', this.query, (data) => {

                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.course = data.data.course

                this.table.loading = false

            })
        },
        follow(item, status) {

            if (item.apply_status === status) return;

            this.$loading.show()

            api.post('/course/follow', {
                id: this.query.id,
                am_id: item.id,
                status: item.apply_status ? 0 : 1
            }, (data) => {

                this.$loading.hide()

                this.$message.data(data)

                if (data.code === 0) {

                    this.getList()

                }
            })

        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        }
    }
}
</script>
<style lang="less" scoped>
.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
    }
}

.level-card {
    border-radius: 8px;
    border: 1px solid #EDC483;
    color: #EDC483;
    text-align: center;
    display: inline-block;
    padding: 2px 6px;
}

.members-table /deep/ table {
    .ant-table-thead > tr > th {
        background: transparent;
        padding: 12px 12px;
    }

    .ant-table-column-title {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
    }

    .ant-table-tbody > tr > td {
        font-size: 14px;
        font-weight: 400;
        color: #606266;
        line-height: 14px;
        padding: 12px 12px;
    }
}

.ant-dropdown-link {
    color: #666666;
}

.gj {
    width: 120px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 40px;
    height: 40px;
    padding-left: 24px;
}

.gj-icon {
    display: inline-block;
    background-image: url("/assets/icons/gj-icon.png");
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
}

.member-info {
    display: flex;
    align-items: center;

    .member-avatar {
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
    }
}

.member-name {
    color: #606266;
}

.member-id {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    margin-top: 7px;
}
</style>